<template>
  <div class="Step1View">
    <div class="my-2 my-md-6">
      <ui-validate @status="({ detail }) => (formStatus = detail)">
        <div class="Step__form pb-0 p-lg-0 position-relative">
          <h1
            class="mb-2"
            data-test-id="signup-user-title"
          >
            {{ $t('refactor.user_signup.step_1.header_title') }}
          </h1>
          <h3
            class="mb-5"
            data-test-id="signup-user-title"
          >
            {{ $t('refactor.user_signup.step_1.header_description') }}
          </h3>
          <ui-text-input
            ref="emailElement"
            v-validate.blurinput.input="{
              isEmail: {
                message: $t('email_input.invalid_email'),
              },
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
              isEmailDuplicated,
            }"
            :disabled="isEmailLocked"
            :label="$t('refactor.user_signup.fields.email')"
            :max-length="200"
            :placeholder="$t('refactor.user_signup.fields.email_placeholder')"
            :value="email"
            autofocus
            class="d-block"
            data-test-id="signup-user-email-input"
            name="email"
            :type="INPUT_TYPES.email"
            @changevalue="({ detail }) => changeEmail(detail)"
            @focusinput="focusEmailInput"
          />
          <ui-text-input
            ref="passwordElement"
            v-validate.blurinput.input="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
              },
              arePasswordRequirementsMet,
            }"
            :icon-left="passwordFieldConfig.icon"
            :label="$t('refactor.user_signup.fields.password')"
            :type="passwordFieldConfig.inputType"
            :value="password"
            data-test-id="signup-user-password-input"
            class="d-block mt-3"
            name="password"
            reverse
            @changevalue="({ detail }) => changePassword(detail)"
            @clickicon="() => (isPasswordRevealed = !isPasswordRevealed)"
            @focusinput="focusPasswordInput"
          />
          <Transition name="fade">
            <PasswordFeedBackComponent
              v-if="hasFocusPasswordInput"
              :password-requirements="PASSWORD_VALIDATIONS"
              :password="password"
              @onPasswordChange="validatePassword"
            />
          </Transition>
          <div class="py-5">
            <ui-button
              v-bind="fetchButtonSpecs()"
              :loading="loading"
              :disabled="!isFormValid"
              :type="BUTTON_TYPES.submit"
              :size="SIZES.large"
              data-test-id="signup-user-next-button"
              @click="submit"
            >
              {{ $t('refactor.user_signup.step_4.create_account') }}
            </ui-button>
          </div>
          <AlreadyHaveAccount class="mb-2" />
          <span v-if="!isInvitation && !preregisteredData">
            <span
              class="emobg-color-ink pr-2"
              data-test-id="company_signup_redirect-text"
            >
              {{ $t('refactor.user_signup.step_1.are_you_company') }}
            </span>
            <RouterLink
              :to="companySignUpRoute"
              class="emobg-font-weight-semibold emobg-color-primary"
              data-test-id="company_signup_redirect-button"
            >
              {{ $t('refactor.user_signup.step_1.are_you_company_click') }}
            </RouterLink>
          </span>
        </div>
      </ui-validate>
    </div>

    <FeedbackModalComponent
      v-model="b2bModal.isOpen"
      v-bind="b2bModal"
      is-closable
      @close-modal="cancelEmployeeSignUp"
    />
  </div>
</template>
<script>
import { BUTTON_TYPES, Validate, VALIDATION_EVENT_MODIFIERS } from '@emobg/vue-base';
import { external } from '@emobg/web-api-client';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  getValue, isEmail, isObjectEmpty, navigationErrorHandler,
} from '@emobg/web-utils';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { SEGMENT_EVENTS } from '@/vue/constants';
import SegmentMixin from '@/mixins/Segment';
import { passwordRegex } from '@/constants/regularExpression';
import { useTheme } from '@/composable/Theme/useTheme';

import { decryptString } from '@/utils/crypto';
import { PASSWORD_VALIDATION } from '@/domains/Account/SignUp/User/constants/PasswordFeedBackValidation.const';
import PasswordFeedBackComponent from '@/domains/Account/SignUp/User/components/PasswordFeedBackComponent';
import AlreadyHaveAccount from '@/domains/Account/components/AlreadyHaveAccount';
import COMPANY_SIGN_UP_ROUTES from '../../router/routes-names';
import { GENERIC_MODULE, USER_MODULE } from '../../constants/modules';
import StepsMixin from '../../StepsMixin';
import { STEPS } from '../constants/steps.const';
import { resetInputValidation } from '../../../utils/validation.util';

export default {
  name: 'Step1View',
  components: {
    PasswordFeedBackComponent,
    FeedbackModalComponent,
    AlreadyHaveAccount,
  },

  directives: {
    Validate,
  },

  mixins: [SegmentMixin, StepsMixin],
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
  data() {
    return {
      formStatus: {},
      isFormDataValid: false,
      forceErrorOnEmailExist: false,
      loading: false,
      isModalOpen: false,
      b2bModal: {
        isOpen: false,
      },
      acceptButtonIsLoading: false,
      hasFocusPasswordInput: false,
      isPasswordRevealed: false,
      isPasswordValid: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      email: (state) => get(state, 'data.email'),
      password: (state) => get(state, 'data.password'),
      b2b: (state) => get(state, 'b2b'),
      isValidInvite: (state) => get(state, 'b2b.invitation.valid_invite'),
      company: (state) => get(state, 'b2b.company'),
      companyName: (state) => get(state, 'b2b.company.commercialName', ''),
      isCorporateUser: (state) => get(state, 'b2b.isCorporateUser', false),
    }),
    ...mapState(GENERIC_MODULE, {
      locale: (state) => state.locale,
    }),
    isInvitation() {
      return this.isQueryInvitation && this.isValidInvite;
    },
    isEmailLocked() {
      return !!this.isInvitation || !!this.preregisteredData;
    },
    passwordFieldConfig() {
      return this.isPasswordRevealed
        ? { inputType: this.INPUT_TYPES.text, icon: this.ICONS.show }
        : { inputType: this.INPUT_TYPES.password, icon: this.ICONS.hide };
    },
    companySignUpRoute() {
      return {
        name: COMPANY_SIGN_UP_ROUTES.step1,
        query: get(this, '$route.query', {}),
      };
    },
    isValidEmail() {
      return isEmail(this.email).isValid;
    },
    isFormValid() {
      const isFullFormValid = getValue(this, 'formStatus.areAllValidated', false) && getValue(this, 'formStatus.isValid', false);
      const isPasswordEmpty = isEmpty(getValue(this, '$refs.passwordElement.value', ''));
      const isPasswordCorrect = getValue(this, '$refs.emailElement.disabled', false) && getValue(this, 'formStatus.isValid', false) && !isPasswordEmpty && this.isPasswordValid;
      return this.isFormDataValid || isFullFormValid || isPasswordCorrect;
    },
  },
  async created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.PASSWORD_VALIDATIONS = PASSWORD_VALIDATION;
    this.preregisteredData = get(this, '$route.params.employeeData');
    this.isQueryInvitation = !!this.$route.params.invitationToken && !!this.$route.params.invitationUUID;
    this.passwordRegex = new RegExp(passwordRegex);
    this.trackSegment({ name: SEGMENT_EVENTS.REGISTER_CLICKED });
    this.validInvite(false);
    this.checkForPromoCode();
    this.checkForInvitation();
    if (this.preregisteredData && !this.email) {
      // Data can be a `employeeUuid` or an `email`
      // `email` is encrypted in the middleware
      const decryptedData = decodeURIComponent(decryptString(decodeURIComponent(this.preregisteredData)));
      const isValidEmail = !!decryptedData && isEmail(decryptedData).isValid;

      const response = await external.userCompanyAccount.getCheckCompanyCustomer({
        [isValidEmail ? 'email' : 'user_uuid']: isValidEmail ? decryptedData : this.preregisteredData,
      });
      this.setData({ email: response.email });
    }
  },
  mounted() {
    this.setB2BData({
      isCorporateUser: false,
    });

    const emailElement = get(this, '$refs.emailElement');
    const passwordElement = get(this, '$refs.passwordElement');
    if (!isObjectEmpty(emailElement.value) || !isObjectEmpty(passwordElement.value)) {
      emailElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
      passwordElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
      passwordElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.focusInput));
    }
  },
  methods: {
    resetInputValidation,
    ...mapMutations(USER_MODULE, [
      'setData',
      'setB2BData',
      'doneStep',
      'undoStep',
      'updateStep',
      'validInvite',
      'b2bCompany',
      'country',
      'setEmail',
      'setPassword',
    ]),
    ...mapActions(USER_MODULE, ['checkInvitationB2B', 'setLegalDocuments']),
    trackEmailPasswordChange() {
      this.trackSegment({
        name: SEGMENT_EVENTS.SET_EMAIL_AND_PASSWORD,
        data: {
          email: this.email,
        },
      });
    },
    changeEmail(email) {
      this.forceErrorOnEmailExist = false;
      this.setEmail(email);
    },
    changePassword(password) {
      this.setPassword(password);
    },
    validatePassword(isPasswordValid) {
      this.isPasswordValid = isPasswordValid;
    },
    async submit() {
      this.loading = true;
      if (this.isInvitation) {
        await this.getLegalDocument();
        this.goToLastStep();
      } else {
        await this.checkEmail();
      }
      this.loading = false;
    },
    checkForPromoCode() {
      if (this.$route.params.promo) {
        this.setData({ promoCode: this.$route.params.promo });
      }
    },
    async checkForInvitation() {
      if (!this.isQueryInvitation) {
        return;
      }

      const { invitationToken, invitationUUID } = this.$route.params;
      this.setB2BData({
        invitationToken,
        invitationUUID,
      });
      await this.checkInvitationB2B();
      this.setB2BData({ isEmployee: this.isValidInvite || this.isCorporateUser });
      const isValidInvite = get(this, 'b2b.invitation.valid_invite');

      const email = isValidInvite
        ? get(this, 'b2b.invitation.invite.invitee_email')
        : '';
      const country = isValidInvite && get(this, 'b2b.invitation.company.countryCode');

      this.validInvite(isValidInvite);
      this.setData({ country, email });
    },
    async getLegalDocument() {
      const dedicatedFleetCsOperator = get(this, 'company.dedicated_fleet_cs_operator')
        || get(this, 'company.dedicatedFleetCsOperator');
      const csOperator = get(this, 'company.cs_operator') || get(this, 'company.csOperator');
      const csOperatorUuid = get(this, 'company.cs_operator_uuid') || get(this, 'company.csOperatorUuid');
      const userCSOperatorUuid = dedicatedFleetCsOperator || csOperator || csOperatorUuid;

      this.country({ code: this.company.countryCode });
      this.b2bCompany({ ...this.company, csOperatorUuid: userCSOperatorUuid });
      this.acceptButtonIsLoading = true;
      this.b2bModal = {
        ...this.b2bModal,
        secondaryCallToActionDisabled: true,
        primaryCallToActionLoading: true,
      };
      await this.setLegalDocuments(this.locale);
      this.acceptButtonIsLoading = false;
      this.b2bModal = {
        ...this.b2bModal,
        secondaryCallToActionDisabled: false,
        primaryCallToActionLoading: false,
      };
    },
    goToLastStep() {
      this.doneStep(STEPS.two);
      this.updateStep(STEPS.three);
      this.$router
        .push({ path: `./${STEPS.three}`, query: this.$route.query })
        .catch(navigationErrorHandler);
    },
    cancelEmployeeSignUp() {
      this.b2bCompany(false);
      this.b2bModal.isOpen = false;
    },
    async checkEmail() {
      try {
      // TODO: [CSTMR-4234] Independent Stores for B2B & B2C Signup
        const response = await external.userCompanyAccount.getCheckCompanyCustomer({
          email: this.email,
        });

        const company = get(response, 'company') || {};
        const isCorporateUser = !!(company.ownsEmployeeData && company.dedicatedFleetCsOperator);

        this.setB2BData({
          csOperator: get(response, 'csOperator'),
          isCorporateUser,
          isEmployee: get(response, 'emailFromCompany'),
        });

        if (response.emailFromCompany || this.preregisteredData) {
          this.setData({ company: company.countryCode });
          this.setB2BData({ company });

          this.b2bModal = {
            description: this.$t('refactor.user_signup.step_1.b2b.text', { company: this.companyName }),
            isOpen: true,
            primaryCallToAction: async () => {
              await this.getLegalDocument();

              this.goToLastStep();
            },
            primaryCallToActionText: this.$t('refactor.user_signup.step_1.b2b.accept_button'),
            secondaryCallToAction: this.cancelEmployeeSignUp,
            secondaryCallToActionText: this.$t('refactor.user_signup.step_1.b2b.cancel_button'),
            title: this.$t('refactor.user_signup.step_1.b2b.title'),
          };
        } else if (this.isFormValid) {
          this.updateStep(STEPS.two);
          this.$router
            .push({ path: `./${STEPS.two}`, query: this.$route.query })
            .catch(navigationErrorHandler);
        }

        this.doneStep(STEPS.one);
      } catch (error) {
        this.forceErrorOnEmailExist = true;
        this.isFormDataValid = false;
        const emailElement = get(this, '$refs.emailElement');
        emailElement.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
      }
    },
    isEmailDuplicated() {
      this.trackEmailPasswordChange();
      return {
        isValid: !this.forceErrorOnEmailExist,
        message: this.$t('refactor.user_signup.step_1.errors.mail_error'),
      };
    },
    arePasswordRequirementsMet() {
      this.trackEmailPasswordChange();
      return {
        isValid: this.isPasswordValid,
        message: ' ',
      };
    },
    focusPasswordInput(event) {
      if (!this.isPasswordValid) {
        this.resetInputValidation(event);
      }
      this.hasFocusPasswordInput = true;
    },
    focusEmailInput(event) {
      if (!this.isValidEmail) {
        this.resetInputValidation(event);
      }
    },
  },
};
</script>
